import {WKT} from "ol/format";
import {getLocationById} from "../http/services/route";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {Icon, Style} from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {loadGeomDependentList} from "../pages/newnotification/map";

export function setLocationByAddressID(addressID, map, layer) {

    const format = new WKT();

    getLocationById(addressID).then((data) => {

        const location = data.response.docs[0]

        const feature = format.readFeature(location.centroide_ll, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
        });

        addNewNotificationLayerClick(feature.getGeometry().flatCoordinates, map, layer)

        map.getView().animate({
            center: feature.getGeometry().flatCoordinates,
            duration: 2000,
            zoom: 14
        });

        $('#iptNewNotificationLocationZipcode').val(location.postcode)
        $('#iptNewNotificationLocationCity').val(location.woonplaatsnaam)
        $('#iptNewNotificationLocationAddress').val(location.straatnaam + ' ' + location.huis_nlt)
    })
}

export function addNewNotificationLayerClick(coordinate, map, layer) {

    loadGeomDependentList(coordinate)

    if(layer != null) {
        map.removeLayer(layer);
    }

    const iconFeature = new Feature({
        geometry: new Point(coordinate),
    });

    const iconStyle = new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: 'assets/img/marker.png',
        }),
    });

    iconFeature.setStyle(iconStyle);

    layer = new VectorLayer({
        name: 'notificationGeometry',
        source: new VectorSource({
            features: [iconFeature],
        })
    })

    map.addLayer(layer);
}
