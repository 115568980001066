import moment from "moment";
import select2 from "select2";

export function initTimePicker() {
    select2($('#iptNotificationTime'))
    $('#iptNotificationTime').select2({
        width: 'calc(100% - 15px)',
        data: [],
        language: {
            noResults: function () {
                return "Geen resultaten gevonden";
            }
        },
        tags: true,
    })

    $('#iptNotificationTime').one('select2:open', function(e) {
        $('input.select2-search__field').prop('placeholder', 'Vul hier zelf een tijdstip in');
    });

}

export function loadTimePickerOptions() {
    let dateToday = moment().format('HH:mm')

    $('#iptNotificationTime').html('')
    const checkDate = moment($('#iptNewNotificationDate').val(), 'DD-MM-YYYY', 'nl')

    let dateHour = parseInt(moment().format('H'))
    let dateMinute = moment().format('mm')
    let dateHourCheck = dateHour

    const checkDiff = checkDate.diff(moment(), 'days')
    if(checkDiff < 0) {
        dateHourCheck = 24;
        dateMinute = 60
    }

    for(var i = 0; i < 23; i++){

        let time = i

        if(i < 10) {
            time = '0' + time
        }

        if(i <= dateHourCheck) {
            $('#iptNotificationTime').append('<option value="' + time + ':00">' + time + ':00</option>')

            if(i === dateHour && dateMinute <= 30) {
                $('#iptNotificationTime').append('<option value="' + dateToday + '">' + dateToday + '</option>')
            }

            if(i < dateHourCheck || (i === dateHourCheck && dateMinute > 30)) {
                $('#iptNotificationTime').append('<option value="' + time + ':30">' + time + ':30</option>')
            }

            if(i === dateHour && dateMinute > 30) {
                $('#iptNotificationTime').append('<option value="' + dateToday + '">' + dateToday + '</option>')
            }
        }
    }

    $('#iptNotificationTime').val(dateToday)
    $('#iptNotificationTime').trigger('change.select2');
}