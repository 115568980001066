
import {initSmartWizard, resetSmartWizard} from "./smartwizard";
import {createFeature} from "../../http/services/mutation";
import {
    getNewNotificationGeometry,
    getNewNotificationLayer,
    getNewNotificationMap,
    getSuspectedLayer,
    initMap,
    resetFeatures
} from "./map";
import {WKT} from "ol/format";
import {initAutoComplete} from "../../route/autocomplete";
import {setCurrentPosition} from "./geolocation";
import {initListGroup} from "./listgroup";
import {transform} from "ol/proj";
import {Point} from "ol/geom";
import {Fill, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {getFileUploads, initFileUpload, resetFileUploads} from "./fileupload";
import {initMyNotifications, isValid} from "../myaccount";
import select2 from "select2";
import moment from "moment";
import {initTimePicker, loadTimePickerOptions} from "./elements/time";
import {initDatePicker} from "./elements/date";
import {initFeatures} from "../home/map";
import {loadUserInfo} from "../auth/twofactorauthentication";
import Swal from "sweetalert2";
import {getLocationById, searchLocation} from "../../http/services/route";
import {setLocationByAddressID} from "../../route/route";
/**
 * Init all jquery functions
 */
export function initNewNotification() {

    $("#addNotification :input").change(function() {
        reloadNewNotificationOverview()
    });

    initDatePicker()

    initMap()

    // SmartWizard initialize
    initSmartWizard()

    initFileUpload()

    // AutoComplete initialize
    initAutoComplete('iptNewNotificationLocation', getNewNotificationMap(), getNewNotificationLayer())

    $('#btnSubmitNewNotification').click(() => {

        let empty = false
        let allEmpty = true
        $('.info-field-required').each(function() {
            if($(this).val() === '') {
                empty = true
            } else {
                allEmpty = false
            }
        })

        const email = $('#iptNewNotificationEmail')
        if(email.val() !== "") {
            const isValidObject = isValid(email.attr('type'), email.val())
            email.closest('.form-group').find('.invalid-feedback').remove()
            if (isValidObject.isValid === false) {
                Swal.fire({
                    title: '',
                    text: 'U heeft geen geldig email adres ingevuld.',
                    icon: '',
                    confirmButtonColor: "#C6153E",
                    confirmButtonText: 'Oke',
                })
                return
            }
        }

        const phoneNumber = $('#iptNewNotificationNumber')
        if(phoneNumber.val() !== "") {
            const isValidObject = isValid(phoneNumber.attr('type'), phoneNumber.val())
            phoneNumber.closest('.form-group').find('.invalid-feedback').remove()
            if (isValidObject.isValid === false) {
                Swal.fire({
                    title: '',
                    text: 'U heeft geen geldig telefoonnummer ingevuld. Spaties en tekens zijn niet mogelijk',
                    icon: '',
                    confirmButtonColor: "#C6153E",
                    confirmButtonText: 'Oke',
                })
                return
            }
        }
        const iptNewNotificationNumberContact = $('#iptNewNotificationNumberContact')
        if(iptNewNotificationNumberContact.prop('checked') && phoneNumber.val() === '') {
            Swal.fire({
                title: '',
                text: 'Telefoonnummer verplicht als we telefonisch contact met u op mogen nemen',
                icon: '',
                confirmButtonColor: "#C6153E",
                confirmButtonText: 'Oke',
            })
            return
        }

        if(allEmpty) {
            Swal.fire({
                title: 'Weet u zeker dat u anoniem wilt melden?',
                text: 'U heeft geen contactgegevens ingevuld. U meld anoniem. U ontvangt hierdoor geen updates over uw melding',
                icon: '',
                confirmButtonText: 'Nee, ik wil updates ontvangen',
                showCancelButton: true,
                confirmButtonColor: "#C6153E",
                cancelButtonColor: "#ffffff",
                cancelButtonText: "Ja, verstuur de melding anoniem",
                customClass: {
                    confirmButton: 'btn-block',
                    cancelButton: 'btn-block'
                },
            }).then(function(result) {
                if (result.value) {

                } else {
                    createNewNotification()
                }
            })
        } else {

            if(window.newNotificationLocation === undefined || window.newNotificationLocation.straatnaam === undefined) {
                Swal.fire({
                    title: '',
                    text: 'U heeft geen geldig postcode en huisnummer combinatie ingevuld.',
                    icon: '',
                    confirmButtonColor: "#C6153E",
                    confirmButtonText: 'Oke',
                })
                loadRouteApi()
            } else if(empty || $('#iptNewNotificationAddress').html() === 'Er is geen adres gevonden') {

                if(empty) {
                    Swal.fire({
                        title: '',
                        text: 'U heeft nog niet alle verplichten velden ingevuld.',
                        icon: '',
                        confirmButtonColor: "#C6153E",
                        confirmButtonText: 'Oke',
                    })
                } else {
                    Swal.fire({
                        title: '',
                        text: 'U heeft geen geldig postcode en huisnummer combinatie ingevuld.',
                        icon: '',
                        confirmButtonColor: "#C6153E",
                        confirmButtonText: 'Oke',
                    })
                }


            } else {
                createNewNotification()
            }
        }
    })

    $('#btnGetCurrentPosition').click(() => {
        setCurrentPosition(getNewNotificationMap(), getNewNotificationLayer())
    })

    initListGroup()

    $('#btnReceiveUpdates').click(() => {
        $('#divReceiveUpdates').show()
        $('#btnSubmitNewNotification').show()
        $('#divAnonymous').hide()
        $('#divNewNotificationSuccessAnonymous').show()
    })

    $('#btnAnonymous').click(() => {
        $('#divReceiveUpdates').hide()
        $('#btnSubmitNewNotification').show()
        $('#divAnonymous').show()
        $('#divNewNotificationSuccessAnonymous').hide()
    })

    $('#slcSuspected').change(function() {

        const value = $(this).val()

        const highlightStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'rgba(0, 87, 129, 0.8)',
                }),
                stroke: new Stroke({
                    color: 'rgba(255, 0, 0, 1)',
                    width: 2
                }),
            }),
        })

        const defaultStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'rgba(0, 87, 129, 0.8)',
                }),
                stroke: new Stroke({
                    color: 'rgba(0, 87, 129, 1)',
                    width: 1
                }),
            }),
        })

        if(getSuspectedLayer() !== null) {
            getSuspectedLayer().getSource().getFeatures().map(feature => {

                if (feature.id_ !== undefined) {
                    if (feature.values_['Naam:'] === value) {
                        feature.setStyle(highlightStyle)
                    } else {
                        feature.setStyle(defaultStyle)
                    }
                }
            })
        }
    })

    select2($('#slcSuspected'))
    $('#slcSuspected').select2({
        width: '100%',
        dropdownParent: $('#divSuspected'),
        dropdownPosition: 'above',
        language: {
            noResults: function () {
                return "Geen resultaten gevonden";
            }
        },
        tags: true,
    })

    $('#slcSuspected').one('select2:open', function(e) {
        $('input.select2-search__field').prop('placeholder', 'Weet u wie de vermoedelijke veroorzaker is, vul dat hier in (niet verplicht)');
    });

    initTimePicker()

    $('#iptNewNotificationHouseNumber, #iptNewNotificationPostalCode').change(() => {
        loadRouteApi()
    })
}

export function loadRouteApi() {
    window.newNotificationLocation = ''
    if($('#iptNewNotificationPostalCode').val() === "" || $('#iptNewNotificationHouseNumber').val() === "") {
        $('#iptNewNotificationAddress').html('')
    } else {
        const text = $('#iptNewNotificationPostalCode').val() + ' ' + $('#iptNewNotificationHouseNumber').val()

        searchLocation(text).then(locations => {

            let suggestion = ''
            locations.suggestions.map((suggestionItem) => {
                if(suggestion === '' && suggestionItem.huisnummer !== '') {
                    suggestion = suggestionItem
                }
            })

            if(locations.suggestions.length === 0 || suggestion === '') {
                $('#iptNewNotificationAddress').html('Er is geen adres gevonden')
            } else {
                getLocationById(suggestion.data.id).then((data) => {
                    window.newNotificationLocation = data.response.docs[0]
                    $('#iptNewNotificationAddress').html(data.response.docs[0].weergavenaam)
                })
            }
        })
    }
}

export function reloadNewNotificationOverview() {
    const formItems = $('#addNotification').serializeArray()

    formItems.map((formItem) => {
        $('#addNotification [data-name="' + formItem.name + '"]').html(formItem.value)
    })

    $('#spnTime').html($('#iptNotificationTime').val())

    const location = $('#iptNewNotificationLocation');

    if(window.user !== undefined) {
        $('#iptNewNotificationEmail').val(window.user.properties.emailadres)
        $('#iptNewNotificationNumber').val(window.user.properties.telefoonnummer)
    }
}

export function resetNewNotification() {
    resetSmartWizard()
    $('#addNotification').serializeArray().map((formItem) => {
        $('#addNotification [data-name="' + formItem.name + '"]').html('')
    })
    $("#addNotification :input").val('');
    $('.list-item-hoofdcategorie').removeClass('active')
    $('#iptNewNotificationDate').val(moment().format('DD-MM-YYYY'))
    $('#slcSuspected').html('')
    resetFileUploads()
    resetFeatures()
    $('#slcSuspectedDistance').val(300)
}

/**
 *
 */
export function createNewNotification() {
    $('#btnSubmitNewNotification').prop('disabled', true)

    const format = new WKT();

    let geom = getNewNotificationGeometry()
    geom = format.writeGeometry(new Point(geom))

    const request = {}
    request.data = []
    request.action = "create"
    const dataItem = {
        geom: geom,
        geom_old: '',
    }

    const formItems = $('#addNotification').serializeArray()
    formItems.map((formItem) => {

        if(formItem.name === 'datum_tijd') {
            formItem.value += ' ' + $('#iptNotificationTime').val()
        }

        dataItem[formItem.name] = formItem.value
        dataItem[formItem.name + '_old'] = ''
    })

    const fileUploads = getFileUploads()

    let counter = 1
    fileUploads.map(fileUpload => {
        dataItem['foto' + counter] = fileUpload;
        dataItem['foto' + counter + '_old'] = '';
        counter++
    })

    if(window.user !== undefined) {
        dataItem['usernaam'] = window.user.id.split('.')[1];
        dataItem['usernaam_old'] = '';
    }

    dataItem['Mozard_huidige_status'] = 'ontvangen'
    dataItem['Mozard_huidige_status_old'] = '';

    // Weather
    dataItem["weertype"] = window.currentWeather.samenv
    dataItem["weertype_old"] = ''

    dataItem["windkracht"] = window.currentWeather.windbft
    dataItem["windkracht_old"] = ''

    dataItem["windrichting"] = window.currentWeather.windr
    dataItem["windrichting_old"] = ''

    // if(dataItem['emailadres'] !== '') {
    //     dataItem["signalering_via_email"] = 'J'
    //     dataItem["signalering_via_email_old"] = ''
    // }

    dataItem["app"] = 'meldingenapp'
    dataItem["app_old"] = ''

    if(window.newNotificationLocation !== undefined) {
        dataItem["moz_naw_adres"] = window.newNotificationLocation.straatnaam + ' ' + window.newNotificationLocation.huisnummer
        dataItem["moz_naw_adres_old"] = ''

        dataItem["moz_naw_woonplaats"] = window.newNotificationLocation.woonplaatsnaam
        dataItem["moz_naw_woonplaats_old"] = ''
    }

    if($('#iptNewNotificationNumberContact').prop('checked')) {
        dataItem["signalering_via_telefoon"] = "J"
    } else {
        dataItem["signalering_via_telefoon"] = "N"
    }

    if($('#iptNewNotificationNumberEmail').prop('checked')) {
        dataItem["signalering_via_email"] = "J"
    } else {
        dataItem["signalering_via_email"] = "N"
    }

    request.data.push(dataItem)

    grecaptcha.ready(function () {
        grecaptcha.execute(window.publicToken, {action: 'create'}).then(function (token) {

            request.token = token

            // Call create API and reset
            createFeature(request).then((result) => {

                resetNewNotification()

                $('#divNav').hide()
                $('#divNewNotificationSuccess').show()

                // Reload home features
                initFeatures(true)
                // Reload user features
                loadUserInfo()

                setTimeout(() => {
                    $('#divNewNotificationSuccess').slideUp(1000)
                    setTimeout(() => {
                        $('#divNav').show()
                        getNewNotificationMap().updateSize();
                    }, 1000);
                }, 3000)

                $('#btnSubmitNewNotification').prop('disabled', false)
            }, () => {
                $('#btnSubmitNewNotification').prop('disabled', false)
            })
        });
    });

}
