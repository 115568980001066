import {generateTwoFactorQrCode, getUser, getUserInfo, verifyKey} from "../../http/services/auth";
import {initMyNotifications, initMySettings} from "../myaccount";
import toastr from "toastr";

export function initTwoFactorAuthentication() {
    $('#btnLoginTwoFactorAuthentication').click(() => {

        const data = {
            'otpkey': $('#iptLoginTwoFactorAuthentication').val()
        }

        verifyKey(data).then( (result) => {
            $('#divAuth').hide()
            $('#myAccount').show()
            $('#divNav').show()
            $('#myAccountAnonymous').hide()
            $('#myAccountLoggedIn').show()

            getUser().then(user => {
                window.loggedIn = true
                window.user = user.features[0]

                $('#spnMyUsername').html(window.user.properties.voornaam)

                initMySettings()

                loadUserInfo()
            })
        }, () => {
            toastr.options.timeOut = 6000;
            toastr.warning('De code onjuist')
        })
    })
}


export function checkTwoFactorAuthentication() {

    $('#iptLoginTwoFactorAuthentication').val('')
    $('#divQrCodeLoginTwoFactorAuthentication').hide()
    $('#divKeyLoginTwoFactorAuthentication').hide()

    const isMobile = mobileCheck()

    generateTwoFactorQrCode(isMobile).then((data) => {
        if(data === 'true') {
            $('#divKeyLoginTwoFactorAuthentication').show()
        } else {
            $('#divQrCodeLoginTwoFactorAuthentication').show()

            if(data.token === undefined) {
                $('#imgQrCodeLoginTwoFactorAuthentication').html(data)
            } else {
                $('#imgQrCodeLoginTwoFactorAuthentication').html('<a href=' + data.token + '>Open de authenticator op je telefoon</>')
            }
        }
    })
}

export function loadUserInfo() {
    getUserInfo().then(userInfo => {
        window.userInfo = userInfo
        initMyNotifications()
    })
}