import moment from "moment";
import {loadTimePickerOptions} from "./time";
import datepicker from "bootstrap-datepicker";

export function initDatePicker() {

    $('#iptNewNotificationDate').val(moment().format('DD-MM-YYYY'))

    $.fn.datepicker.dates['nl'] = {
        days:["zondag","maandag","dinsdag","woensdag","donderdag","vrijdag","zaterdag"],
        daysShort:["zo","ma","di","wo","do","vr","za"],
        daysMin:["zo","ma","di","wo","do","vr","za"],
        months:["januari","februari","maart","april","mei","juni","juli","augustus","september","oktober","november","december"],
        monthsShort:["jan","feb","mrt","apr","mei","jun","jul","aug","sep","okt","nov","dec"],
        today:"Vandaag",
        monthsTitle:"Maanden",
        clear:"Wissen",
        weekStart:1,
        format:"dd-mm-yyyy"
    };

    $('#iptNewNotificationDate').datepicker({
        format: 'dd-mm-yyyy',
        weekStart: 1,
        endDate:moment().format('DD-MM-YYYY'),
        language: 'nl'
    }).on('changeDate', function(e) {
        loadTimePickerOptions()
    });
}