import {performHttpRequest} from "../index";

export async function searchLocation(q) {
    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/routeapi/location/lng/4.61185/lat/52.4859163?cbsCodes=&query=' + q,
        'GET',
    )
}

export async function getLocationById(q) {
    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/routeapi/location/lookup/' + q,
        'GET',
    )
}

export async function getLocationsByCoordinates(lat, lng) {
    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/routeapi/location/lng/' + lng + '/lat/' + lat,
        'GET',
    )
}
export async function getLocationReversed(lat, lng) {
    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/routeapi/location/lng/' + lng + '/lat/' + lat + '/reversed',
        'GET',
    )
}