import {getHomeLayer, getHomeMap, initFeatures, loadMap} from "./map";
import {initFilter} from "./filter";
import {setCurrentPosition} from "../newnotification/geolocation";
import {initAutoComplete} from "../../route/autocomplete";
import {initSlider} from "./slider";

export function initHome() {
    loadMap()
    initFilter()
    initFeatures()
    initSlider()
    initAutoComplete('iptHomeFindAddress', getHomeMap(), getHomeLayer())

    $('#btnHomeFindAddress').click(() => {
        setCurrentPosition(getHomeMap(), getHomeLayer())
    })

    $('#btnHomeToggleList').click(() => {
        hideFeatureInfo()
        homeToggleList()
    })

    $('#btnHomeToggleMap').click(() => {
        hideFeatureInfo()
        homeToggleMap()
    })

    $('#btnFilter').click(function() {
        hideFeatureInfo()
        $('#mdlFeatureInfo').hide()
    })

    $('#btnFeatureInfoClose').click( () => {
        $('#mdlFeatureInfo').hide()
    })
}

export function hideFeatureInfo() {
    $('#btnFeatureInfoClose').trigger('click')
}

export function homeToggleMap() {
    $('#btnHomeToggleMap').hide()
    $('#btnHomeToggleList').show()
    $('#homeList').hide()
    $('#homeMap').show()

    getHomeMap().updateSize();
}

export function homeToggleList() {
    $('#btnHomeToggleMap').show()
    $('#btnHomeToggleList').hide()
    $('#homeList').show()
    $('#homeMap').hide()

    getHomeMap().updateSize();
}