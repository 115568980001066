import Slider from "bootstrap-slider";
import {loadFilter} from "./map";

export function initSlider() {

    const slider = new Slider("#iptFilterSlider")
    slider.on('change', function(ev){
        getSliderValue()

        loadFilter()
    });
    const myNotificationsSlider = new Slider("#iptFilterMyNotificationsSlider")
    myNotificationsSlider.on('change', function(ev){
        getMyNotificationSliderValue()

        loadFilter()
    });
}

export function getSliderValue() {
    let days = 0

    switch (parseInt($('#iptFilterSlider').val())) {
        // Week
        case 1:
            $('#divFilterSlider').html('Vandaag')
            $('#spnTableTitleSlider').html('Vandaag')
            days = 1
            break;
        // Month
        case 2:
            $('#divFilterSlider').html('Laatste 4 dagen')
            $('#spnTableTitleSlider').html('Laatste 4 dagen')
            days = 4
            break;
        // Quarter
        case 3:
            $('#divFilterSlider').html('Laatste 7 dagen')
            $('#spnTableTitleSlider').html('Laatste 7 dagen')
            days = 7
            break;
    }

    return days
}

export function getMyNotificationSliderValue() {
    let days = 0

    switch (parseInt($('#iptFilterMyNotificationsSlider').val())) {
        // Week
        case 1:
            $('#divFilterMyNotificationsSlider').html('Vandaag')
            days = 1
            break;
        // Month
        case 2:
            $('#divFilterMyNotificationsSlider').html('Laatste week')
            days = 7
            break;
        // Quarter
        case 3:
            $('#divFilterMyNotificationsSlider').html('Laatste maand')
            days = 30
            break;
        case 4:
            $('#divFilterMyNotificationsSlider').html('Laatste kwartaal')
            days = 90
            break;
        case 5:
            $('#divFilterMyNotificationsSlider').html('Laatste jaar')
            days = 365
            break;
    }

    return days
}