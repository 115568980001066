/**
 *
 * @param url
 * @param type
 * @param data
 * @param cache
 * @param token
 * @returns {Promise<{getAllResponseHeaders: function(): *|null, abort: function(*=): this, setRequestHeader: function(*=, *): this, readyState: number, getResponseHeader: function(*): null|*, overrideMimeType: function(*): this, statusCode: function(*=): this}|*|jQuery>}
 */
export async function performHttpRequest(url,
                                    type,
                                    data = null,
                                    cache = false,
                                    token = false) {

    let options = {
        cache: cache,
        type: type,
        url: url,
        data: data
    }

    if(token) {
        options.headers = {
            "Authorization": window.token
        }
    }

     return $.ajax(options);
}