import smartwizard from "smartwizard";
import {getNewNotificationGeometry, getNewNotificationLayer, getNewNotificationMap} from "./map";
import {getWeatherData} from "../../http/services/weather";
import toastr from "toastr";

export function initSmartWizard() {
    smartwizard($('#smartwizard'))
    window.smartWizard = $('#smartwizard').smartWizard({
        autoAdjustHeight: false,
        backButtonSupport: false,
        enableURLhash: false,
        true: true,
        theme: 'dots',
        lang: { // Language variables for button
            next: 'Volgende',
            previous: 'Vorige'
        }
    })

    window.smartWizard.on("leaveStep", function (e, anchorObject, stepIndex, stepDirection) {

        // console.log(stepDirection)

        switch (stepIndex) {
            case 0:
                if(getNewNotificationLayer() === null) {
                    toastr.options.timeOut = 6000;
                    toastr.options.preventDuplicates = true;
                    toastr.options.preventOpenDuplicates = true;
                    toastr.warning('Om verder te gaan moet u eerst een locatie kiezen')
                    return false
                }
                break;
            case 1:
                if(!$('.list-item-hoofdcategorie').hasClass('active')) {
                    toastr.options.timeOut = 6000;
                    toastr.options.preventDuplicates = true;
                    toastr.options.preventOpenDuplicates = true;
                    toastr.warning('Om verder te gaan moet u een soort overlast kiezen')
                    return false;
                }
            break;
        }
    })

    window.smartWizard.on("stepContent", function(e, anchorObject, stepIndex, stepDirection) {
        if(stepIndex === 1 && stepDirection === 'forward') {
            const geometry = getNewNotificationGeometry('EPSG:4326')
            getWeatherData(geometry[0], geometry[1]).then(result => {
                window.currentWeather = result.liveweer[0]
            })
        }
    })

    window.smartWizard.on("showStep", function(e, anchorObject, stepIndex, stepDirection) {
        if(stepIndex === 1) {
            getNewNotificationMap().updateSize();
        }
    });
}

export function resetSmartWizard() {
    window.smartWizard.smartWizard("reset");

    getNewNotificationMap().updateSize();
}
