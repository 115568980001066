import {performHttpRequest} from "../index";

export async function login(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/login',
        'POST',
        data
    )
}

export async function register(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/register',
        'POST',
        data
    )
}

export async function updateUser(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user',
        'PUT',
        data,
        false,
        true
    )
}

export async function updateUserByToken(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user',
        'PUT',
        data,
        false
    )
}

export async function logout() {
    return true;
}

export async function getUser() {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user',
        'GET',
        null,
        false,
        true
    )
}

export async function getUserInfo() {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user/info?srsName=EPSG%3A28992&outputFormat=application%2Fjson',
        'GET',
        null,
        false,
        true
    )
}

export async function activateUser(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user/activate',
        'POST',
        data
    )
}

export async function forgotPassword(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/user/forgotpassword',
        'POST',
        data
    )
}

export async function generateTwoFactorQrCode(isMobile) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/generateKey?isMobile=' + isMobile,
        'GET',
        null,
        false,
        true
    )
}

export async function verifyKey(data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/auth/verifyKey',
        'POST',
        data,
        null,
        true
    )
}

export async function checkPasswordRequest(password) {
    return await performHttpRequest(
        window.kaartviewerUrl  + '/admin/rest/auth/password/check',
        'POST',
        password
    );
}