import {reloadNewNotificationOverview} from "./index";

export function initListGroup() {
    $('.list-group-item-action').click(function() {
        $('.list-group-item-action').removeClass('active')
        $(this).addClass('active')
        $('#slcNewNotificationWhich').val($(this).data('value'))
        reloadNewNotificationOverview()
    })
}
