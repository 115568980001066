import {getUser, getUserInfo, login, verifyKey} from "../../http/services/auth";
import {initRegister} from "./register";
import {initForgotPassword} from "./forgotpassword";
import toastr from "toastr";
import {initMyNotifications, initMySettings} from "../myaccount";
import {goToFeatureByID, goToMyFeatureByID} from "../home/map";
import {checkTwoFactorAuthentication, initTwoFactorAuthentication} from "./twofactorauthentication";

export function initLogin() {

    initTwoFactorAuthentication()

    $('#divMyNotifications').on('click', 'div', function () {
        const id = $(this).data('id')

        goToMyFeatureByID(id)

        $('.page-link-item').each(function() {
            if($(this).data('page-id') === 'home') {
                $(this).trigger('click')
            }
        })
    })

    $('#btnLogin').click(() => {

        const data = {}
        data.username = $('#iptUsername').val()
        data.password = $('#iptPassword').val()

        if(data.username === '') {
            toastr.options.timeOut = 6000;
            toastr.warning('Gebruikersnaam mag niet leeg zijn')
            return
        }

        if(data.password === '') {
            toastr.options.timeOut = 6000;
            toastr.warning('Wachtwoord mag niet leeg zijn')
            return
        }

        login($('#frmLogin').serialize()).then((result) => {

            window.token = result.token

            $('#frmLogin').hide()
            $('#frmLoginTwoFactorAuthentication').show()
            $('#divRegisterLink').hide()

            checkTwoFactorAuthentication()
        }, () => {
            toastr.options.timeOut = 6000;
            toastr.warning('De combinatie van gebruikersnaam en wachtwoord is onjuist')
        })

    })

    initRegister()
    initForgotPassword()
}

export function showLogin() {
    $('#myAccountAnonymous').hide()
    $('#frmLoginTwoFactorAuthentication').hide()
    $('#divRegister').hide()
    $('#divAuth').show()
    $('#divLogin').show()
    $('#frmLogin').show()

}