import {getLocationReversed} from "../../http/services/route";
import {setLocationByAddressID} from "../../route/route";

export function setCurrentPosition(map, layer) {

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            window.currentPosition = position

            getLocationReversed(position.coords.latitude, position.coords.longitude).then((result) => {
                $('#iptNewNotificationLocation').val(result.adres.weergavenaam)
                setLocationByAddressID(result.adres.id, map, layer)
            })
        });
    }
}