
import { Dropzone } from "dropzone";
import {reloadNewNotificationOverview} from "./index";
import toastr from "toastr";
import Swal from "sweetalert2";

let fileUploads = []
const element = '#divNotificationFileUpload'

export function initFileUpload() {
    const dropzone = new Dropzone(element, {
        headers: {
            'Cache-Control': null,
            'X-Requested-With': null,
        },
        addRemoveLinks: true,
        maxFiles: 3,
        maxFilesize: 32,
        paramName: 'upload',
        dictRemoveFile: 'Verwijder bestand',
        dictCancelUpload: 'Bestand upload annuleren',
        dictFileTooBig: 'Het bestand is te groot ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.',
        dictUploadCanceled: 'Bestand upload geannuleerd.',
        url: window.kaartviewerUrl + '/admin/rest/openapi/' +  window.websiteName + '/hindermelding/mutation/uploadfile'
    })

    dropzone.on("sending", function(file) {
        console.log(file.name)
        if(file.name.match(/[^a-zA-Z0-9_\-\.]/) !== null) {
            dropzone.cancelUpload(file);

            Swal.fire({
                title: '',
                text: 'Alleen letters, cijfers, _ of - zijn toegestaan als bestandsnaam.',
                icon: '',
                confirmButtonColor: "#C6153E",
                confirmButtonText: 'Oke',
            })
        }
    });

    dropzone.on("success", file => {
        const fileUpload = JSON.parse(file.xhr.responseText)
        fileUploads.push(fileUpload.upload.id.fileName)

        if(getFileUploads().length === 1) {
            $('#spnFileUploads').html('Er is 1 bestand toegevoegd')
        } else {
            $('#spnFileUploads').html('Er zijn 2 bestanden toegevoegd')
        }

        reloadNewNotificationOverview()
    })
}

export function getFileUploads() {
    return fileUploads
}

export function resetFileUploads() {
    fileUploads = []
    Dropzone.forElement(element).removeAllFiles(true)
}
