import {getDomainListByAttributeName} from "../../http/services/filter";
import {hideLayerByName, showLayerByName} from "./map";

export function initFilter() {

    const filterItems = [
        {
            'DisplayName': 'Soort overlast',
            'Attribute': 'wat_voor_overlast_wilt_u_melden'
        },
        {
            'DisplayName': 'Status',
            'Attribute': 'Mozard_huidige_status'
        }
    ]

    filterItems.map(filterItem => {
        let result = '<label class="form-label text-primary">' + filterItem.DisplayName + '</label>'
        result += '<div style="height: 45px;overflow-x: auto;white-space: nowrap;padding-top: 3px;">'

        grecaptcha.ready(function () {
            grecaptcha.execute(window.publicToken, {action: filterItem.Attribute}).then(function (token) {
                getDomainListByAttributeName(filterItem.Attribute, token).then((domainListItems) => {

                    domainListItems.features.map(domainListItem => {
                        result += '<button type="button" data-attribute="' + filterItem.Attribute + '" data-value="' + domainListItem.properties.value + '" class="btn btn-sm rounded-pill btn-outline-primary btnFilter">' + domainListItem.properties.name + '</button>'
                    })
                    result += '</div>'
                    $('#divFilterItems').append(result)
                })
            })
        })
    })

    $('#chbFilterToggleOwnNotifications').prop('checked', false)
    $('#chbFilterToggleOwnNotifications').change(function() {
        if($(this).prop('checked')) {
            $('#divFilterSliderBlock').hide()
            $('#divFilterMyNotificationsSliderBlock').show()
            hideLayerByName('allNotifications')
            showLayerByName('myNotifications')
        } else {
            $('#divFilterMyNotificationsSliderBlock').hide()
            $('#divFilterSliderBlock').show()
            hideLayerByName('myNotifications')
            showLayerByName('allNotifications')
        }
    })
    // layer.setVisible(!layer.getVisible())
}
