import toastr from "toastr";
import {checkPasswordRequest, register} from "../../http/services/auth";
import {isValid} from "../myaccount";

export function initRegister() {

    $('#btnRegister').click(() => {
        $('#divLogin').hide()
        $('#divRegister').show()
    })

    $('#btnRegisterBack').click(() => {
        $('#divRegister').hide()
        $('#divLogin').show()
    })

    $('#btnRegisterSubmit').click(() => {

        let valid = true

        $('.iptRegisterItem').each(function () {

            const isValidObject = isValid($(this).attr('type'), $(this).val())
            $(this).closest('.form-group').find('.invalid-feedback').remove()
            if(isValidObject.isValid === false) {
                valid = false
                $(this).closest('.form-group').append('<label class="small form-text invalid-feedback m-0" style="display: inline-block;">' + isValidObject.message + '</label>')
            }
        })

        if(!$('#chbRegisterPrivacy').prop('checked') && valid) {
            valid = false
            toastr.options.timeOut = 6000;
            toastr.warning('Vink privacyverklaring aan om te registreren')
        }

        if(valid) {

            register($('#frmRegister').serialize()).then(() => {
                $('#divRegister').hide()
                $('#divLogin').show()

                toastr.options.timeOut = 6000;
                toastr.success('Account is aangemaakt. Er is een e-mail verstuurd waarmee u uw account kunt activeren.')
            })
        }
    })

    const newUserPassword = $('#iptRegisterPassword');
    const newUserPasswordCheck = $('#iptRegisterPasswordCheck');
    const divUserPasswordMessage = $('#divUserPasswordMessage');
    const btnActivateAccount = $('#btnRegisterSubmit');

    newUserPassword.keyup(function () {
        checkPassword();
    });

    newUserPasswordCheck.keyup(function () {
        checkPassword();
    });

    function checkPassword() {
        const value = newUserPassword.val();
        const valueCheck = newUserPasswordCheck.val();

        checkPasswordRequest({password: value, passwordCheck: valueCheck}).then((result) => {

            divUserPasswordMessage.html('');
            result.items.map((item) => {
                if (item[0] === 1) {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-success d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-check"></i></li>');
                } else {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-warning d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-exclamation-triangle"></i></li>');

                }
            });

            if (result.approved) {
                btnActivateAccount.prop('disabled', false);
            } else {
                btnActivateAccount.prop('disabled', true);
            }
        });
    }
}

export function showRegister() {
    $('#myAccountAnonymous').hide()
    $('#divLogin').hide()
    $('#divRegister').show()
    $('#divAuth').show()
}
