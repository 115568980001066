import {initLogout} from "../auth/logout";
import {showLogin} from "../auth/login";
import {initRegister, showRegister} from "../auth/register";
import {addMyNotificationsLayer, convertDateTime, goToFeatureByID} from "../home/map";
import {getFeatures} from "../../http/services/info";
import {checkPasswordRequest, register, updateUser, updateUserByToken} from "../../http/services/auth";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import {isEmpty} from "ol/obj";
import toastr from "toastr";
import {initEditUser} from "../auth/editUser";
import * as grecaptcha from "bootstrap/js/src/util";

export function initMyAccount() {

    $('#btnLogout').click(() => {
        initLogout()
    })

    $('.btnMyAccountBack').click(() => {
        $('#myAccountEditNotification').hide()
        $('#myAccountEditUserData').hide()
        $('#myAccount').show()

        $('#myAccountAnonymous').hide()
        $('#myAccountLoggedIn').show()
    })


    $('#btnMyAccountLogin').click(() => {
        showLogin()
    })

    $('#btnMyAccountRegister').click(() => {
        showRegister()
    })

    $('.iptRegisterItem').change(function () {
        const valid = isValid($(this).attr('type'), $(this).val()).isValid
        // console.log(valid)
    })

    $('#btnChangePasswordSubmit').click(() => {
        $('#divChangePassword').hide()
        $('#divLogin').show()
        updateUserByToken($('#frmChangePasswordSubmit').serialize()).then( () => {
            toastr.options.timeOut = 6000;
            toastr.success('Uw wachtwoord is succesvol gewijzigd')
        })
    })

    $('#btnChangePasswordBack').click(() => {
        $('#divChangePassword').hide()
        $('#divLogin').show()
    })

    initEditUser()
}

export function isValid(type, value) {

    let isValid = {}

    switch (type) {
        case 'tel':
            isValid.isValid = isValidPhone(value)
            isValid.message = 'Telefoon nummer is ongeldig'
            break;
        case 'email':
            isValid.isValid  = isEmail(value)
            isValid.message = 'Email is ongeldig'
            break;
        default:
            isValid.isValid  = !isEmpty(value)
            isValid.message = 'Deze waarde mag niet leeg zijn'
            break;
    }

    return isValid
}

export function initMyNotifications(reload = false) {
    if(reload) {
        grecaptcha.ready(function () {
            grecaptcha.execute(window.publicToken, {action: 'info'}).then(function (token) {
                getFeatures(token).then((result) => {
                    window.notificationFeatures = result

                    loadNotifications()
                });
            })
        })
    } else {
        loadNotifications()
    }
}

export function initMySettings() {
    const formItems = $('#frmUpdateUser').serializeArray()

    $('#frmUpdateUser input').each(function() {
        const name = $(this).data('name')
        $(this).val(window.user.properties[name])
    })
}

export function loadNotifications() {
    $('#divMyNotifications').html('')
    if(window.user !== undefined) {
        addMyNotificationsLayer(window.userInfo)
        $('#divFilterShowOwnNotifications').show()
        window.userInfo.features.map(feature => {

                // if (parseInt(feature.properties.usernaam) === parseInt(window.user.id.split('.')[1])) {

                    $('#divMyNotifications').append('<hr>' +
                        '<div class="row no-gutters divMyNotifications" style="overflow:inherit;" data-id="' + feature.id + '">' +
                        '  <div class="col-12 align-items-center card-hover-danger">' +
                        '    <div class="text-body">' +
                        '      <div class="text-primary">' +
                        '        <span class="text-capitalize">' + feature.properties.Mozard_huidige_status + '</span>' +
                        '        <small class="float-right">' + convertDateTime(feature.properties.datum_tijd) + '</small>' +
                        '      </div>' +
                        '      <div class="media-body d-block ">' +
                        '        <div class="small">Melding type: ' + feature.properties.wat_voor_overlast_wilt_u_melden + '</div>' +
                        '        <div class="small">Vermoedelijke veroorzaker: ' + feature.properties.vermoedelijke_veroorzaker + '</div>' +
                        '        <div class="small">Omschrijving: ' + feature.properties.toelichting + '</div>' +
                        '      </div>' +
                        '    </div>' +
                        '  </div>' +
                        '</div>')
            // }
        })
    } else {
        $('#divFilterShowOwnNotifications').hide()
    }
}

function isValidPhone(value) {

    // Starts with zero
    if(!value.startsWith("0")) {
        return false
    }

    // Has spaces
    if(value.indexOf(' ') >= 0) {
        return false
    }

    // Only contains digits
    if(!/^\d+$/.test(value)) {
        return false
    }

    return true
}
