import {createMap} from "../../ol";
import {getFeatures} from "../../http/services/info";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import {Fill, Icon, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";
import moment from "moment";
import {getMyNotificationSliderValue, getSliderValue} from "./slider";
import {homeToggleMap} from "./index";
import toastr from "toastr";

export function loadMap() {
    createMap('map')

    getHomeMap().updateSize();

    getHomeMap().getView().on('change:resolution', (event) => {
        // console.log("zoom changed");
        // console.log(getHomeMap().getView().getZoom())

        if(getHomeMap().getView().getZoom() > 14) {
            toastr.options.timeOut = 6000;
            toastr.options.preventDuplicates = true;
            toastr.options.preventOpenDuplicates = true;
            toastr.success('De punten op de kaart verdwijnen om privacyredenen')
        }
    });
}

export function getHomeMap() {
    return maps[0].map
}

export function getHomeLayer() {
    let homeLayer = null

    getHomeMap().getLayers().forEach(layer => {
        if (layer && layer.get('name') === 'notificationGeometry') {
            homeLayer = layer
        }
    });

    return homeLayer
}

let vectorSourceMyNotification
let vectorLayer
let vectorSource

export function goToFeatureByID(id) {
    vectorLayer.getSource().getFeatures().map(feature => {
        if(feature.id_ === id) {
            activateFeature(feature)

            homeToggleMap()

            getHomeMap().getView().animate({
                center: feature.getGeometry().flatCoordinates,
                duration: 2000,
                zoom: 14
            });
        }
    })
}

export function initFeatures(reload = false) {

    grecaptcha.ready(function () {
        grecaptcha.execute(window.publicToken, {action: 'info'}).then(function (token) {
            getFeatures(token).then((result) => {

                window.notificationFeatures = result
                vectorSource = new VectorSource({
                    features: new GeoJSON({
                        dataProjection: 'EPSG:28992',
                        featureProjection: 'EPSG:3857'
                    }).readFeatures(result),
                })

                vectorLayer = new VectorLayer({
                    name: 'allNotifications',
                    minResolution: 9,
                    source: vectorSource,
                    style: styleFunction,
                })

                loadFilter()
                getHomeMap().addLayer(vectorLayer);

                if (!reload) {
                    getHomeMap().on('click', function (evt) {
                        displayFeatureInfo(evt, vectorLayer);
                    });

                    $(document).on('click', '.btnFilter', function () {
                        $(this).toggleClass('active')

                        loadFilter()
                    })

                    $('#tblHomeBody').on('click', 'tr', function () {
                        const id = $(this).data('id')

                        goToFeatureByID(id)
                    })
                }
            })
        })
    })
}

export function loadFilter() {

    let filterSource = vectorSource
    let sliderValue = getSliderValue()
    if($('#chbFilterToggleOwnNotifications').prop('checked')) {
        filterSource = vectorSourceMyNotification
        sliderValue = getMyNotificationSliderValue()
    }

    const filterItems = []
    $('.btnFilter').each(function () {
        if($(this).hasClass('active')) {
            filterItems.push({
                'attribute': $(this).data('attribute'),
                'value': $(this).data('value')
            })
        }
    })

    const uniqueLength = [...new Set(filterItems.map(filterItem => filterItem.attribute))].length

    $('#tblHomeBody').html('')

    let filterTableCount = 0

    // console.log(filterSource.getFeatures())

    const filterSourceFeatures = filterSource.getFeatures()

    const filterSourceFeaturesSorted = filterSourceFeatures.sort(
        (objA, objB) => Number(new Date(objB.values_.datum_tijd)) - Number(new Date(objA.values_.datum_tijd)),
    );

    // console.log(filterSourceFeaturesSorted)

    filterSourceFeaturesSorted.map((feature) => {

        const difference = moment().diff(moment(feature.values_.datum_tijd), 'days')

        if(difference > sliderValue) {
            // console.log('more then ' + sliderValue + ' days')
            feature.setStyle(new Style())
        } else {

            if (filterItems.length === 0) {
                feature.setStyle(styleFunction);

                $('#tblHomeBody').append('<tr data-id="' + feature.id_ + '"><td>' + convertText(feature, 'wat_voor_overlast_wilt_u_melden') + '</td><td>' + convertText(feature, 'Mozard_huidige_status') + '</td><td>' + convertDateTime(feature.values_.datum_tijd, '') + '</td><td>' + convertText(feature, 'plaats_waarneming') + '</td></tr>')
                filterTableCount++
            } else {
                let hideFeature = 0

                filterItems.map(filterItem => {
                    if (feature.values_[filterItem.attribute] === filterItem.value) {
                        hideFeature++
                    }
                })

                if (hideFeature === uniqueLength) {
                    feature.setStyle(styleFunction);

                    $('#tblHomeBody').append('<tr data-id="' + feature.id_ + '"><td>' + convertText(feature, 'wat_voor_overlast_wilt_u_melden') + '</td><td>' + convertText(feature, 'Mozard_huidige_status') + '</td><td>' + convertDateTime(feature.values_.datum_tijd, '') + '</td><td>' + convertText(feature, 'plaats_waarneming') + '</td></tr>')
                    filterTableCount++
                } else {
                    feature.setStyle(new Style());
                }
            }

        }
    })

    $('#spnFilterTableCount').html(filterTableCount)

    filterSource.changed()
}

export function convertText(feature, attribute) {
    const value = feature.values_[attribute]
    if(value === undefined || value === null || value === '') {
        return '-'
    }
    return value
}

export function convertDateTime(date, separatorText = 'om') {
    if(date === undefined || date === null || date === '') {
        return '-'
    }

    const addDays = date.substring(21, 22)

    date = moment(date.slice(0, -6))
    date.add(addDays, 'hours')

    return date.format('DD-MM-YYYY [' + separatorText + '] H:mm')
}

export function styleFunction(feature) {

    if(feature.values_.wat_voor_overlast_wilt_u_melden === null) {
        return new Style()
    }

    return new Style({
        image: new Icon({
            crossOrigin: 'anonymous',
            src: 'https://helpdesk.kaartviewer.nl/iconen/Overlastmeldingen/' + feature.values_.wat_voor_overlast_wilt_u_melden + '_24x.png',
            // src: 'https://helpdesk.kaartviewer.nl/iconen/Overlastmeldingen/' + feature.values_.welke_hinder_heeft_u_ervaren + '_24x.png',
        }),
    })
}

export function highlightStyleFunction() {

    // console.log('higlight')

    return new Style({
        image: new CircleStyle({
            radius: 10,
            fill: new Fill({
                color: 'rgba(0, 87, 129, 0.6)',
            }),
            stroke: new Stroke({color: 'red', width: 2}),
        })
    })
}

export function activateFeatures(features) {

    let innerHTML = ''

    features.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.values_.datum_tijd) - new Date(a.values_.datum_tijd)
    });

    features.map(feature => {
        innerHTML += `     
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <div class="text-muted">Melding</div>
                <div>
                    <img height="20px" style="margin-top:-3px" src="https://helpdesk.kaartviewer.nl/iconen/Overlastmeldingen/${feature.values_.wat_voor_overlast_wilt_u_melden}_240x.png">
                    ${feature.values_.wat_voor_overlast_wilt_u_melden}
                
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <div class="text-muted">Status</div>
                <div>${feature.values_.Mozard_huidige_status}</div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <div class="text-muted">Datum</div>
                <div>${convertDateTime(feature.values_.datum_tijd)}</div>
              </li>
            </ul>`
    })

    $('#mdlFeatureInfo').show()

    $('#mdlFeaturesInfoBody').html(innerHTML)

    $('#mdlFeatureInfoBody').hide()
    $('#mdlFeaturesInfoBody').show()
}

export function activateFeature(feature) {


    $('#mdlFeatureInfoBody').show()
    $('#mdlFeaturesInfoBody').hide()

    $('.divFeatureInfoLoggedIn').hide()

    if(feature !== undefined) {

        $('#divFeatureInfoNotification').html(feature.values_.wat_voor_overlast_wilt_u_melden)
        $('#divFeatureInfoStatus').html(feature.values_.Mozard_huidige_status)


        if(window.user !== undefined && parseInt(feature.values_.usernaam) === parseInt(window.user.id.split('.')[1])) {
            $('#divFeatureInfoSuspected').html(feature.values_.vermoedelijke_veroorzaker)
            $('#divDescription').html(feature.values_.toelichting)
            $('.divFeatureInfoLoggedIn').show()
        }

        if (feature.values_.datum_tijd === null) {
            $('#divFeatureInfoDate').html('-')
        } else {
            $('#divFeatureInfoDate').html(convertDateTime(feature.values_.datum_tijd))
        }


        // console.log('mdlFeatureInfo show')

        feature.setStyle([styleFunction(feature)]);

        highlightedFeature = feature

        $('#mdlFeatureInfo').show()
    } else {
        $('#mdlFeatureInfo').hide()
    }

}

let highlightedFeature
const displayFeatureInfo = function (evt, vectorLayer) {

    vectorLayer.getSource().getFeatures().map(feature => {
        if(highlightedFeature === feature) {
            feature.setStyle(styleFunction(feature));
        }
    })

    const features = []
    getHomeMap().forEachFeatureAtPixel(evt.pixel, function (f, layer) {
        console.log(f)
        features.push(f)
    })
    activateFeatures(features)

    // vectorLayer.getFeatures(evt.pixel).then(function (features) {
    //
    //     // console.log(features)
    //     //
    //     // const feature = features.length ? features[0] : undefined;
    //     //
    //     // // console.log(vectorLayer.values_.name)
    //     //
    //     // if($('#chbFilterToggleOwnNotifications').prop('checked') && vectorLayer.values_.name === 'myNotifications') {
    //     //     activateFeatures(feature)
    //     // }
    //     // if(!$('#chbFilterToggleOwnNotifications').prop('checked') && vectorLayer.values_.name === 'allNotifications') {
    //     //     activateFeature(feature)
    //     // }
    // });
};

export function addMyNotificationsLayer(notifications) {
    vectorSourceMyNotification = new VectorSource({
        features: new GeoJSON({
            dataProjection: 'EPSG:28992',
            featureProjection: 'EPSG:3857'
        }).readFeatures(notifications),
    })

    const vectorLayerMyNotification = new VectorLayer({
        name: 'myNotifications',
        source: vectorSourceMyNotification,
        style: styleFunction,
    })

    getHomeMap().addLayer(vectorLayerMyNotification);

    getHomeMap().on('click', function (evt) {
        displayFeatureInfo(evt, vectorLayerMyNotification);
    });
}

export function hideLayerByName(name) {
    getHomeMap().getLayers().forEach(layer => {
        if(layer.get('name') === name) {
            layer.setVisible(false)
        }
    });
}

export function showLayerByName(name) {
    getHomeMap().getLayers().forEach(layer => {
        if(layer.get('name') === name) {
            layer.setVisible(true)
        }
    });
}

export function goToMyFeatureByID(id) {
    vectorSourceMyNotification.getFeatures().map(feature => {
        if(feature.id_ === id) {
            activateFeature(feature)

            getHomeMap().getView().animate({
                center: feature.getGeometry().flatCoordinates,
                duration: 2000,
                zoom: 14
            });
        }
    })
}
