import {initPages} from "../pages/pages";
import {getNewNotificationMap, initMap} from "../pages/newnotification/map";
import {getHomeMap} from "../pages/home/map";
import moment from "moment";
import {resetSmartWizard} from "../pages/newnotification/smartwizard";
import {loadTimePickerOptions} from "../pages/newnotification/elements/time";
import {initTwoFactorAuthentication} from "../pages/auth/twofactorauthentication";

export function initNav() {

    $('#divNav').show()

    if(window.loggedIn) {
        $('#myAccountAnonymous').hide()
        $('#myAccountLoggedIn').show()
    }

    initPages()

    $('.page-link-item').on('click', function() {
        $('#divAuth').hide()
        $('.page-item').hide()

        $('.nav-link').removeClass('active')
        $('.nav-item').removeClass('active')
        $(this).find('.nav-link').addClass('active')
        $(this).addClass('active')

        const pageID = $(this).data('page-id');

        $('#' + pageID).show()

        switch (pageID) {
            case 'home':
                getHomeMap().updateSize();
                break;
            case 'addNotification':
                loadTimePickerOptions()
                resetSmartWizard()
                // inactive active
                break;
            case 'myAccount':
                if(window.loggedIn) {
                    $('#myAccountAnonymous').hide()
                    $('#myAccountEditNotification').hide()
                    $('#myAccountEditUserData').hide()
                    $('#myAccountLoggedIn').show()
                } else {
                    $('#myAccountAnonymous').show()
                    $('#myAccountLoggedIn').hide()
                    $('#divChangePassword').hide()
                }
                break;
            case 'myAccountEditNotification':
                $('#myAccount').show()
                $('#myAccountLoggedIn').hide()
                break;
            case 'myAccountEditUserData':
                $('#myAccount').show()
                $('#myAccountLoggedIn').hide()
                break;
        }
    })

    $('.btn').click(function () {
        // console.log('updateSize')
        getNewNotificationMap().updateSize();
    })
}

export function resetNav() {
    $('.page-item').hide()
    $('#home').show()
}
