import {checkPasswordRequest, forgotPassword} from "../../http/services/auth";
import toastr from "toastr";

export function initForgotPassword() {

    $('#btnForgotPassword').click(() => {
        $('#divLogin').hide()
        $('#divForgotPassword').show()
    })

    $('#btnForgotPasswordBack').click(() => {
        $('#divForgotPassword').hide()
        $('#divLogin').show()
    })

    $('#btnForgotPasswordSubmit').click(() => {
        $('#divForgotPassword').hide()
        $('#divLogin').show()

        forgotPassword($('#frmForgotPassword').serializeArray()).then(() => {
            toastr.options.timeOut = 6000;
            toastr.success('Als dit e-mail adres bij ons bekend is ontvangt u een e-mail met de instructies om uw wachtwoord te wijzigen')
        })
    })

    const newUserPassword = $('#iptForgotPassword');
    const newUserPasswordCheck = $('#iptForgotPasswordCheck');
    const divUserPasswordMessage = $('#divForgotPasswordMessage');
    const btnActivateAccount = $('#btnChangePasswordSubmit');

    newUserPassword.keyup(function () {
        checkPassword();
    });

    newUserPasswordCheck.keyup(function () {
        checkPassword();
    });

    function checkPassword() {
        const value = newUserPassword.val();
        const valueCheck = newUserPasswordCheck.val();

        checkPasswordRequest({password: value, passwordCheck: valueCheck}).then((result) => {

            divUserPasswordMessage.html('');
            result.items.map((item) => {
                if (item[0] === 1) {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-success d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-check"></i></li>');
                } else {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-warning d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-exclamation-triangle"></i></li>');

                }
            });

            if (result.approved) {
                btnActivateAccount.prop('disabled', false);
            } else {
                btnActivateAccount.prop('disabled', true);
            }
        });
    }
}