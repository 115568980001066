import autocomplete from "autocompleter";
import {searchLocation} from "../http/services/route";
import {setLocationByAddressID} from "./route";
import {getNewNotificationLayer, getNewNotificationMap} from "../pages/newnotification/map";

window.suggestions = []
window.isSearching = false

export function initAutoComplete(id, map, layer) {
    const input = document.getElementById(id);

    autocomplete({
        input: input,
        fetch: function(text, update) {
            text = text.toLowerCase();
            // you can also use AJAX requests instead of preloaded data
            const suggestions = []
            searchLocation(text).then(locations => {
                window.isSearching = true
                window.suggestions = locations.suggestions
                locations.suggestions.map(location => {
                    const suggestion = {}
                    suggestion.label = location.value
                    suggestion.value = location.data.id
                    suggestions.push(suggestion)
                })
                update(suggestions);
            })
        },
        onSelect: function(item) {
            window.isSearching = false
            input.value = item.label;
            setLocationByAddressID(item.value, map, layer)
            if(map !== getNewNotificationMap()) {
                setLocationByAddressID(item.value, getNewNotificationMap(), layer)
            }
        }
    });

    input.addEventListener('change', function (e) {

        if(getNewNotificationLayer() === null || window.isSearching) {
            if(window.suggestions.length > 0) {
                window.isSearching = false
                setLocationByAddressID(window.suggestions[0].data.id, map, layer)
                $('#iptNewNotificationLocation').val(window.suggestions[0].value)
            }
        }
        // console.log(e)
    })
}
