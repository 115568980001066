import TileLayer from "ol/layer/Tile";
import {TileWMS} from "ol/source";

export function initWms(url, layers, name) {
    return new TileLayer({
        name: 'luchtfoto',
        zIndex: -1,
        source: new TileWMS({
            url: url,
            visible: false,
            params: {
                'LAYERS': layers,
                'TILED': false
            },
            // Countries have transparency, so do not fade tiles:
            transition: 0,
            crossOrigin: 'anonymous'
        }),
    })
}