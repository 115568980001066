import {Map, View} from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import {fromLonLat} from "ol/proj";
import {initWms} from "./layer/wms";
import {Attribution, Control, defaults} from "ol/control";
import {getNewNotificationMap} from "../pages/newnotification/map";
import {getHomeMap} from "../pages/home/map";

window.maps = []
window.attribution = new Attribution({
    collapsible: false,
});

export class RotateNorthControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     * @param target
     */
    constructor(opt_options, target) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.className = 'btnLuchtFoto'
        button.type = 'button'
        button.style.backgroundImage = 'url("assets/img/lufo.png"'

        const element = document.createElement('div');
        element.className = 'ol-switch-layer ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleRotateNorth.bind(this, target), false);
    }

    handleRotateNorth(event, target) {

        let hasLuchtfoto = false
        const map = getMapByTarget(event)

        map.getLayers().forEach(layer => {

            if(layer.get('name') === 'osm') {
                layer.setVisible(!layer.getVisible())

                if(layer.getVisible()) {
                    $('.btnLuchtFoto').css('background-image', 'url("assets/img/lufo.png"')
                }
            }

            if(layer.get('name') === 'luchtfoto') {
                hasLuchtfoto = true
                layer.setVisible(!layer.getVisible())

                if(layer.getVisible()) {
                    $('.btnLuchtFoto').css('background-image', 'url("assets/img/kaart.png"')
                }
            }

        });

        if(!hasLuchtfoto) {
            map.addLayer(initWms('https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?', 'Actueel_ortho25', 'luchtfoto'))
            $('.btnLuchtFoto').css('background-image', 'url("assets/img/kaart.png"')
        }
    }
}


export function createMap(target, zoom = 10) {


    const map = {}
    map.target = target

    const checkMapExists = maps.find(element => element.target === target);

    if(checkMapExists === undefined) {
        map.map = new Map({
            name: target,
            target: target,
            layers: [
                new TileLayer({
                    zIndex: -1,
                    name: 'osm',
                    source: new OSM()
                })
           ],
            controls: defaults({
                attribution: false
            }).extend(
            [
                    attribution,
                    new RotateNorthControl(this, target)
                ]
            ),
            view: new View({
                center: fromLonLat([4.805358631036693, 52.42062079948628]),
                zoom: zoom,
                projection: 'EPSG:3857',
            }),
        });


        window.addEventListener('resize', checkSize(map.map));
        // checkSize();

        maps.push(map)
    }

    return map
}

export function checkSize(map) {
    const small = map.getSize()[0] < 600;
    attribution.setCollapsible(small);
    attribution.setCollapsed(small);
}


export function getMapByTarget(target) {
    return window.maps.find(element => element.target === target).map;
}