import {logout} from "../../http/services/auth";
import {resetNav} from "../../layout/nav";
import {resetNewNotification} from "../newnotification";

export function initLogout() {

    logout().then((result) => {
        $('#myAccount').hide()
        $('#myAccountAnonymous').show()
        $('#myAccountLoggedIn').hide()
        $('#frmUpdateUser input').val('')
        $('#divMyNotifications').html('')
        $('#spnMyUsername').html('')
        resetNav()
        resetNewNotification()

        window.loggedIn = false
        delete window.token
    });
}