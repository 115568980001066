import {performHttpRequest} from "../index";

export async function createFeature(data) {

    return performHttpRequest(
        // window.kaartviewerUrl + '/admin/rest/openapi/' +  window.websiteName + '/hindermelding/mutation',
        window.kaartviewerUrl + '/admin/rest/openapi/' +  window.websiteName + '/hindermelding/mutation',
        'POST',
        data
    )
}

// export async function loadDomainListItems(attribute, data) {
//
//     return performHttpRequest(
//         window.kaartviewerUrl + '/admin/rest/openapi/' +  window.websiteName + '/hindermelding/mutation/domainlist?attribute=' + attribute,
//         'POST',
//         data
//     )
// }
export async function loadDomainListItems(attribute, data) {

    return performHttpRequest(
        window.kaartviewerUrl + '/admin/rest/openapi/' +  window.websiteName + '/hindermelding/mutation/domainlist?attribute=' + attribute,
        'POST',
        data
    )
}
