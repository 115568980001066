import {createMap} from "../../ol";
import {transform,} from 'ol/proj';
import {getLocationById, getLocationReversed} from "../../http/services/route";
import {addNewNotificationLayerClick} from "../../route/route";
import {loadDomainListItems} from "../../http/services/mutation";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import {Fill, Stroke, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";
import {Feature} from "ol";
import {Circle} from "ol/geom";

let activeCoordinate
let map
let selected = null;
const selectStyle = new Style({
    image: new CircleStyle({
        radius: 5,
        fill: new Fill({
            color: 'rgba(0, 87, 129, 0.8)',
        }),
        stroke: new Stroke({
            color: 'rgba(0, 87, 129, 1)',
            width: 1
        }),
    }),
    text: new Text({
        text: '',
        offsetY: -10,
        scale: 1.5
    })
});

export function initMap() {
    map = createMap('mapNewNotification', 10).map
    $('#slcSuspectedDistance').val(300)
    map.on('click', (evt) => {

        const pixel = map.getEventPixel(evt.originalEvent);

        if(getSuspectedLayer() === null) {
            loadMapClick(evt)
        } else {
            displayFeatureInfo(pixel).then(function (features) {
                const feature = features.length ? features[0] : undefined

                // console.log(feature)

                let name = undefined
                if(feature !== undefined) {
                    name = feature['values_']['Naam:']

                    if(name !== undefined) {
                        $("#slcSuspected").val(name).trigger('change');
                    }
                }

                // Check if clicked on a suspected
                if (feature === undefined || name === undefined) {
                    loadMapClick(evt)
                }
            });
        }
    });

    map.on('pointermove', function (e) {


        if (selected !== null) {
            selected.setStyle(undefined);
            selected = null;
        }

        map.forEachFeatureAtPixel(e.pixel, function (f) {

            if(f['values_']['Naam:'] !== undefined) {
                selected = f;
                selectStyle.getText().setText(f['values_']['Naam:']);
                f.setStyle(selectStyle);
                return true;
            }
            return false

        });
    });

    $('#slcSuspectedDistance').change(function() {
        loadGeomDependentList(activeCoordinate)
    })
}

export function loadMapClick(evt) {
    const result = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326')
    getLocationReversed(result[1], result[0]).then((locationReversed) => {
        getLocationById(locationReversed.adres.id).then((result) => {

            const location = result.response.docs[0]
            $('#iptNewNotificationLocationZipcode').val(location.postcode)
            $('#iptNewNotificationLocationAddress').val(location.straatnaam + ' ' + location.huis_nlt)
            $('#iptNewNotificationLocationCity').val(location.woonplaatsnaam)
        })
        $('#iptNewNotificationLocation').val(locationReversed.adres.weergavenaam)
    })

    addNewNotificationLayerClick(evt.coordinate, map, getNewNotificationLayer())
}

export function loadGeomDependentList(coordinate) {

    activeCoordinate = coordinate
    const radiusDutch = parseInt($('#slcSuspectedDistance').val())
    const radius = radiusDutch * 1.9

    const coordinateDutch = transform(coordinate, 'EPSG:3857', 'EPSG:28992')

	const circle = new Circle(coordinateDutch, radiusDutch)
    const data = {}
    data.geometry = {}
    data.geometry.type = 'Point'
    data.geometry.coordinates = coordinateDutch
	data.bbox = circle.getExtent()

    loadDomainListItems('vermoedelijke_veroorzaker', data).then( (result) => {

        $('#slcSuspected').html('')
        $('#slcSuspected').append('<option value="">Kies een vermoedelijke veroorzaker</option>')
        result.features.map(feature => {
            $('#slcSuspected').append('<option value="' + feature.properties['Naam:'] + '">' + feature.properties['zoek_veld'] + '</option>')
        })

        const suspectedLayer = getSuspectedLayer()
        if(suspectedLayer !== null) {
            getNewNotificationMap().removeLayer(suspectedLayer);
        }

        const styleFunction = function (feature) {

            switch (feature.getGeometry().getType()) {
                case 'Point':
                   return new Style({
                        image: new CircleStyle({
                            radius: 5,
                            fill: new Fill({
                                color: 'rgba(0, 87, 129, 0.8)',
                            }),
                            stroke: new Stroke({
                                color: 'rgba(0, 87, 129, 1)',
                                width: 1
                            }),
                        }),
                    })
                case 'Circle':
                    return new Style({
                        stroke: new Stroke({
                            color: 'rgba(255,0,0,0.3)',
                            width: 2,
                        }),
                        fill: new Fill({
                            color: 'rgba(255,0,0,0.1)',
                        }),
                    })
            }
        };

        const vectorSource = new VectorSource({
            features: new GeoJSON({
                dataProjection: 'EPSG:28992',
                featureProjection: 'EPSG:3857'
            }).readFeatures(result),
        })

        let circle = new Circle(coordinate, radius)

        vectorSource.addFeature( new Feature(circle));
        // vectorSource.addFeature(new Feature(new Circle([5e6, 7e6], 1e6)));

        const vectorLayer = new VectorLayer({
            name: 'suspected',
            source: vectorSource,
            style: styleFunction,
        })

        getNewNotificationMap().addLayer(vectorLayer);
    })
}

export function getNewNotificationMap() {
    return maps[1].map
}

export function getSuspectedLayer() {

    let suspectedLayer = null

    getNewNotificationMap().getLayers().forEach(layer => {
        if (layer && layer.get('name') === 'suspected') {
            suspectedLayer = layer
        }
    });

    return suspectedLayer
}

export function getNewNotificationLayer() {

    let newNotificationLayer = null

    getNewNotificationMap().getLayers().forEach(layer => {
        if (layer && layer.get('name') === 'notificationGeometry') {
            newNotificationLayer = layer
        }
    });

    return newNotificationLayer
}

export function resetFeatures() {
    getNewNotificationMap().removeLayer(getSuspectedLayer());
}

export function getNewNotificationGeometry(transformDestination = 'EPSG:28992') {
    const layer = getNewNotificationLayer()
    const feature = layer.getSource().getFeatures()[0]
    return transform(feature.getGeometry().flatCoordinates, 'EPSG:3857', transformDestination)
}

export function displayFeatureInfo(pixel) {
    return getSuspectedLayer().getFeatures(pixel)
}
