import {checkPasswordRequest, updateUser} from "../../http/services/auth";

export function initEditUser() {

    const btmAccountEditUserDataSubmit = $('#btmAccountEditUserDataSubmit')
    const newUserPassword = $('#iptEditPassword');
    const newUserPasswordCheck = $('#iptEditPasswordCheck');
    const divUserPasswordMessage = $('#divEditUserPasswordMessage');

    btmAccountEditUserDataSubmit.click(() => {
        $('#myAccountEditUserData').hide()
        $('#myAccount').show()
        $('#myAccountLoggedIn').show()

        updateUser($('#frmUpdateUser').serialize()).then( () => {

        })
    })

    newUserPassword.keyup(function () {
        checkPassword();
    });

    newUserPasswordCheck.keyup(function () {
        checkPassword();
    });

    function checkPassword() {
        const value = newUserPassword.val();
        const valueCheck = newUserPasswordCheck.val();

        checkPasswordRequest({password: value, passwordCheck: valueCheck}).then((result) => {

            divUserPasswordMessage.html('');
            result.items.map((item) => {
                if (item[0] === 1) {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-success d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-check"></i></li>');
                } else {
                    divUserPasswordMessage.append('<li class="list-group-item list-group-item-warning d-flex justify-content-between align-items-center">' + item[1] + ' <i class="fa fa-exclamation-triangle"></i></li>');
                }
            });

            if (result.approved) {
                btmAccountEditUserDataSubmit.prop('disabled', false);
            } else {
                btmAccountEditUserDataSubmit.prop('disabled', true);
            }
        });
    }
}